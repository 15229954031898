body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-weight: 400;
  line-height: 1.75;
  overflow-wrap: break-word;
}

* {
  font-family: "Work Sans", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --white: #fff;
  --link-color: #4f55c4;
  --border-radius: 8px;
  --box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  --box-shadow-hover: rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;

  --gray-100: #f9fafb;
  --gray-200: #f4f6f8;
  --gray-300: #dfe3e8;
  --gray-400: #c4cdd5;
  --gray-500: #919eab;
  --gray-600: #637381;
  --gray-700: #454f5b;
  --gray-800: #212b36;
  --gray-900: #161c24;

  --bg-color: #ffffff;
  --primary-color: #7635dc;
  --primary-color-dark: #431a9e;

  --primary-color-lighter: #f4eefd;
  --primary-color-light: #b985f4;
  --gray-text-color: #454f5b;
  --gray-bg-color: #f4f6f8;
  --line-color: #dfe3e8;

  --secondary-color: #d6e4ff;
}

/* @media (prefers-color-scheme: light) {
  :root {
    --bg-color: #ffffff;
    --primary-color: #7635dc;
    --primary-color-dark: #431a9e;

    --primary-color-lighter: #f4eefd;
    --primary-color-light: #b985f4;
    --gray-text-color: #454f5b;
    --gray-bg-color: #f4f6f8;
    --line-color: #dfe3e8;

    --secondary-color: #d6e4ff;
  }
} */

/* @media (prefers-color-scheme: dark) {
  * {
    color: var(--gray-text-color);
  }

  :root {
    --bg-color: #161c24;
    --primary-color: #7635dc;
    --primary-color-dark: #200a69;

    --primary-color-lighter: #252142;
    --primary-color-light: #b985f4;
    --gray-text-color: #919eab;
    --gray-bg-color: #212b36;
    --line-color: #454f5b;

    --secondary-color: #091a7a;
  }
} */

p {
  margin: 0.5rem 0rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 3rem 0 0rem;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 1.802rem;
}

h2 {
  font-size: 1.602rem;
}

h3 {
  font-size: 1.424rem;
}

h4 {
  font-size: 1.266rem;
}

h5 {
  font-size: 1.125rem;
}

small,
.text_small {
  font-size: 0.889rem;
}

html {
  scroll-behavior: smooth;
}
